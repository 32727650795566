const Bitbox = {
  startBitBoxBridge: async () => {
    // Not implemented
  },
  stopBitBoxBridge: async () => {
    // Not implemented
  }
};

export { Bitbox };
